exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cookies-policies-tsx": () => import("./../../../src/pages/cookiesPolicies.tsx" /* webpackChunkName: "component---src-pages-cookies-policies-tsx" */),
  "component---src-pages-fibexty-tsx": () => import("./../../../src/pages/fibexty.tsx" /* webpackChunkName: "component---src-pages-fibexty-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-us-tsx": () => import("./../../../src/pages/JoinUs.tsx" /* webpackChunkName: "component---src-pages-join-us-tsx" */),
  "component---src-pages-privacy-policies-tsx": () => import("./../../../src/pages/privacyPolicies.tsx" /* webpackChunkName: "component---src-pages-privacy-policies-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/termsConditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-codebuild-output-src-086036051-src-fib-betty-content-blog-tener-un-buen-educador-financiero-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/codebuild/output/src086036051/src/fib-betty/content/blog/tener-un-buen-educador-financiero.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-codebuild-output-src-086036051-src-fib-betty-content-blog-tener-un-buen-educador-financiero-mdx" */)
}

